import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/employment.css"
import application from "../documents/employment_application5-4-21.pdf"
import directCareDescription from "../documents/support_staff_description.pdf"
import techDescription from "../documents/licensed_psychiatric_tech_description.pdf"
import houseManagerDescription from "../documents/house_manager_description.pdf"

export default function EmploymentPage(props) {
  return (
    <Layout>
      <Seo title="Employment" />
      <div class="contentBox">
        <h1>Employment</h1>
        <div className="employment-container">
          <h2 className="employment-header">Positions</h2>
          <div>
            <h4 className="job-title">Direct Care Staff</h4>
            <a href={directCareDescription} download>
              Job Description and Qualifications
            </a>
          </div>
          <div>
            <h4 className="job-title">Licensed Pyschiatric Technician</h4>
            <a href={techDescription} download>
              Job Description and Qualifications
            </a>
          </div>
          <div>
            <h4 className="job-title">House Manager</h4>
            <a href={houseManagerDescription} download>
              Job Description and Qualifications
            </a>
          </div>
        </div>
        <div className="employment-container">
          <h2 className="employment-header">Apply Online</h2>
          <div>
            <a href={application} download>
              Mars Group Application
            </a>
            <p>
              Email completed application{" "}
              <a href="mailto:mcharles@marsgroupusa.net?subject=Application">
                here
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
